import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "twin.macro"
import Hero from "../components/competitions/Hero"
import AboutComps from "../components/competitions/AboutComps"

const CompetitionsPage = () => (
  <Layout>
    <SEO title="Competitions" />
    <Hero />
    <AboutComps />
  </Layout>
)

export default CompetitionsPage
