import * as React from "react"
import tw from "twin.macro"
import styled from "@emotion/styled"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-75`

const HeroContainer = styled.div`
  min-height: 48rem;

  ${tw`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`}
`
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-snug -mt-24 sm:mt-0 mb-12`}
  span {
    ${tw`inline-block mt-2`}
  }
`

const Subheading = styled.p`
  ${tw`text-xl text-center font-medium text-blue-300 leading-snug max-w-4xl mx-auto mt-6 leading-relaxed`}
`

export default () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "competitions_hero_bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div tw="relative">
      <div tw="z-10 absolute inset-0">
        <Img
          fluid={data.file.childImageSharp.fluid}
          style={{ width: "100%", height: "100%" }}
        />
      </div>
      <OpacityOverlay />
      <HeroContainer>
        <Content>
          <Heading>Competitions</Heading>
          <Subheading>
            FBLA offers over 70 competitions which can be grouped into five main
            categories: individual objective, individual oral, impromptu,
            prejudged, and technology. No matter what you’re interested in
            pursuing, MVFBLA has something for you!
          </Subheading>
          <Subheading>
            Want to know what competition is best for you?
            <br />
            Take the online competitions quiz!
          </Subheading>
          <Link
            to="/competitions-quiz"
            tw="rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-blue-500 text-gray-100 hocus:bg-blue-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline"
          >
            Take the Competitions Quiz
          </Link>
        </Content>
      </HeroContainer>
    </div>
  )
}
