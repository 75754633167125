import * as React from "react"
import "twin.macro"

export default function AboutComps() {
  return (
    <>
      <div tw="relative py-16 bg-white overflow-hidden">
        <div tw="relative px-4 sm:px-6 lg:px-8">
          <div tw="text-lg max-w-prose mx-auto mb-6">
            <p tw="text-base text-center leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
              For Existing Members
            </p>
            <h1 tw="mt-2 mb-8 text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              Competition Check-In Forms
            </h1>
          </div>
          <div tw="prose prose-lg text-gray-500 mx-auto">
            <p>
              The following check-in forms are for current members only. If
              you're new to FBLA, scroll down to learn more about Competitions!
            </p>
            <ul>
              <li>
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSdcWepCIMOLSb-DH42LLdz5fAK7ZqwlvwqzW3Kk01Xgiycvuw/viewform?usp=sf_link">
                  Prejudged
                </a>
              </li>
              <li>
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSed9ENRumYS5c0U016T3tbT-5vTgtL6wEc2Trf8beMwAziN0g/viewform?usp=sf_link">
                  Indiv Oral
                </a>
              </li>
              <li>
                <a href="#">Objective</a>
              </li>
              <li>
                <a href="#">Impromptu</a>
              </li>
              <li>
                <a href="#">Tech</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div tw="relative py-16 bg-white overflow-hidden">
        <div tw="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div tw="relative h-full text-lg max-w-prose mx-auto">
            <svg
              tw="absolute top-12 left-full transform translate-x-32"
              width="404"
              height="384"
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    tw="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="384"
                fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
              />
            </svg>
            <svg
              tw="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width="404"
              height="384"
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    tw="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="384"
                fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
              />
            </svg>
            <svg
              tw="absolute bottom-12 left-full transform translate-x-32"
              width="404"
              height="384"
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    tw="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="384"
                fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
              />
            </svg>
          </div>
        </div>
        <div tw="relative px-4 sm:px-6 lg:px-8">
          <div tw="text-lg max-w-prose mx-auto mb-6">
            <p tw="text-base text-center leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
              About Competitions
            </p>
            <h1 tw="mt-2 mb-8 text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              What are Competitions?
            </h1>
          </div>
          <div tw="prose prose-lg text-gray-500 mx-auto">
            <p>
              With over 70 competitions grouped into five main categories:
              individual objective, individual oral, impromptu, prejudged, and
              technology, MVFBLA provides its members with opportunities to grow
              in every field. The competition season begins in February with the
              Bay Section Leadership Conference. In April, MVFBLA attends the
              State Leadership Conference, which is then followed by the
              National Leadership Conference in late June.
            </p>
            <p>
              Our competitions branch conducts a series of interviews in order
              to place members on the best competition for them, keeping
              preferences in mind. We prioritize underclassmen success by
              ensuring that each competition team has an experienced
              upperclassman and our individual competitors get support from our
              entire officer team.
            </p>
            <p>
              Competitions help our members cultivate their public speaking
              skills while honing research and test taking strategies, boost
              confidence during award ceremonies, and, of course, provide
              lifelong memories of fun during conferences.
            </p>
            <p>
              Below is a brief description about each of the five main
              categories of competitions!
            </p>
            <h2>Prejudged</h2>
            <p>
              A team competition composed of two parts: a prejudged submission
              and a 7-minute speech. For the prejudged portion, participants are
              tasked with addressing a given prompt to create detailed
              submissions, ranging from a report, presentation, or video, while
              adhering to California FBLA’s standards. Qualifying competitors
              deliver 7-minute presentations at the States and National
              Leadership Conferences. Teams are often composed of strong public
              speakers, writers, and researchers to successfully carry out all
              aspects of the competition. Time management is a highly valued
              skill, as planning and scheduling are necessary in order to
              complete submissions in a timely manner.{" "}
            </p>
            <h2>Indiv Oral</h2>
            <p>
              An individual competition that is centered around public-speaking
              and presentations. This competition involves no testing.
              Participants will be required to either give an impromptu speech,
              present a pre-written speech, or respond to a real-world business
              scenario. Although it is an individual competition, competitors
              will be able to work together and be a part of the MVFBLA family.
              Competitors should be self-motivated, responsible, and comfortable
              with public speaking. In this competition, being timely and
              following a reasonable schedule for completing certain tasks is
              vital for success. In addition to this, you must be very
              comfortable asking for help anytime you are stuck.{" "}
            </p>
            <h2>Objective</h2>
            <p>
              Objective competitions consist of 60 minute multiple choice test
              on one of 40 plus topics members can choose to study in depth.
              Competitors are lead through each one of their competencies by our
              officer team, and will become topic experts before competition
              season begins. With our extensive study material, MVFBLA has been
              able to give as many resources as necessary to ensure the success
              of our competitors. Participants should be able to manage their
              own time and motivate themselves, but will work with several other
              members with similar competitions and become a part of the MVFBLA
              family. Additionally, competitors must be willing to reach out to
              fellow competitors or officers for help whenever needed beyond
              regular check-ins.{" "}
            </p>
            <h2>Impromptu</h2>
            <p>
              A team-based, spontaneous event, Impromptu competitions’
              presentations differ from other categories in that they are not
              pre-written, meaning teams have to extemporaneously respond to a
              given prompt. However, just like other competitions, there is a
              big focus on testing, with a one hour multiple choice test. The
              teams begin each tournament by taking the test. After low-scoring
              teams are eliminated, the remaining teams will be asked to deliver
              their prompt. A second elimination process ensues, and the
              remaining teams move on to the next level. Participants who
              compete in impromptu will be able to develop a strong foundation
              in speaking skills and self-motivated study skills. With MVFBLA’s
              extensive resources, we are able to provide our teams with the
              support they need to succeed in their events and become topic
              experts in their field with the help of the upperclassman on every
              team.
            </p>
            <h2>Tech</h2>
            <p>
              Tech comps are team-based events that have a tech-related element.
              Some tech comps involve actual programming, while others involve
              animation, video production, graphic design, etc. Some tech comps
              are prejudged, meaning you prepare a product beforehand and “demo”
              it during the actual competition, while others are Impromptu
              events that are heavily related with technology. For more
              information on “prejudged” vs “impromptu” events, refer to their
              corresponding sections above!
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
